import type { AwsRumConfig } from 'aws-rum-web';

import amplifyConfig from '@/amplifyconfiguration.json';
import featureFlags from '@/utilities/feature-flags';

const APP_REGION = amplifyConfig.aws_project_region;
const APP_VERSION = import.meta.env.PACKAGE_VERSION;

type ENV_NAMES = 'devnew' | 'staging' | 'prod';

const RUM_AUTH_CONFIG: Record<ENV_NAMES, { guestRoleArn: string; identityPoolId: string }> = {
  devnew: {
    guestRoleArn: 'arn:aws:iam::278804535517:role/RUM-Monitor-us-west-2-278804535517-9061626003071-Unauth',
    identityPoolId: 'us-west-2:bf968c6e-7b6e-45cc-9cbe-9003fe10c45e',
  },
  prod: {
    guestRoleArn: 'arn:aws:iam::497586388326:role/RUM-Monitor-us-west-2-497586388326-5953780203071-Unauth',
    identityPoolId: 'us-west-2:aca2d642-41f2-438b-98cb-5802e005283d',
  },
  staging: {
    guestRoleArn: 'arn:aws:iam::008681337058:role/RUM-Monitor-us-west-2-008681337058-5318270203071-Unauth',
    identityPoolId: 'us-west-2:53db318a-6df6-45ba-9441-bf31eedf2be5',
  },
};

const RUM_APPLICATION_ID: Record<ENV_NAMES, string> = {
  devnew: '32ea1168-4b22-458f-9b63-55fa182a0bce',
  prod: 'f9a2dd5f-7a5d-46de-8058-12318cce2084',
  staging: 'd31f4e28-a140-4252-b7a9-950a53bf7809',
};

function getApplicationId() {
  if (import.meta.env.MODE === 'development') return '';
  return RUM_APPLICATION_ID[import.meta.env.REACT_APP_BUILD_ENV];
}

function getMonitorConfig(): AwsRumConfig {
  return {
    ...(import.meta.env.MODE === 'development' ? {} : RUM_AUTH_CONFIG[import.meta.env.REACT_APP_BUILD_ENV]),
    sessionSampleRate: 1,
    endpoint: `https://dataplane.rum.${amplifyConfig.aws_project_region}.amazonaws.com`,
    telemetries: ['errors', 'performance', 'http'],
    allowCookies: true,
    enableXRay: featureFlags.XRAY_ENABLED.value,
    enableRumClient: featureFlags.RUM_ENABLED.value,
    disableAutoPageView: true,
  };
}

export default [getApplicationId(), APP_VERSION, APP_REGION, getMonitorConfig()] as const;
